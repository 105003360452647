function setupDropdowns() {
    const dropdowns = document.querySelectorAll('.select-menu');
    const allInputs = document.querySelectorAll('input');

    // Function to close all dropdowns
    function closeAllDropdowns() {
        dropdowns.forEach(dropdown => {
            dropdown.classList.remove('active');
        });
    }

    dropdowns.forEach(dropdown => {
        const selectBtn = dropdown.querySelector('.select-btn'),
            options = dropdown.querySelectorAll('.option'),
            sBtn_text = dropdown.querySelector('.sBtn-text');

        selectBtn.addEventListener('click', (event) => {
            // Close all other dropdowns first
            closeAllDropdowns();
            // Then toggle the active class of the current dropdown
            dropdown.classList.toggle('active');
            // Prevent this event from bubbling up to the document level
            event.stopPropagation();
        });

        options.forEach(option => {
            option.addEventListener("click", (event) => {
                let selectedOption = option.querySelector('.option-text').innerText;
                sBtn_text.innerText = selectedOption;
                dropdown.classList.remove('active'); // Close dropdown after selection
                event.stopPropagation(); // Prevent this event from bubbling up to the document level
            });
        });
    });

    // Close all dropdowns if clicking outside of any dropdown
    document.addEventListener('click', closeAllDropdowns);

    // Close dropdowns when other form elements are focused
    allInputs.forEach(input => {
        input.addEventListener('focus', (event) => {
            // Close all dropdowns if the focused element isn't part of a dropdown
            if (!event.target.closest('.select-menu')) {
                closeAllDropdowns();
            }
        });
    });
}

export default setupDropdowns; // Make sure to export the function correctly