import setupDropdowns from "./dropdown";

function profileAddMoreLang() {
    const addButtonLang = document.getElementById('add-language');
    const languageContainer = document.querySelector('.edit-languages');

    if (!addButtonLang || !languageContainer) {
        console.error('');
        return;
    }

    if (!addButtonLang.dataset.initialized) {
        addButtonLang.dataset.initialized = true;

        addButtonLang.addEventListener('click', function(event) {
            event.preventDefault();

            const newLanguageHtml = `
               <div class="row gy-2 language-section my-border-top">
                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-lang2" class="form-label register-label font-psb">Иностранные языки</label>
                        <div class="select-menu ">
                            <div class="select-btn login-input" id="validationTooltip-lang2">
                                <span class="sBtn-text">Выберите</span>
                                <i class="fi fi-ts-angle-down"></i>
                            </div>
                            <ul class="options">
                                <li class="option">
                        <span class="option-text">
                            Среднее
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Среднее специальное
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Не оконченное высшее (Бакалавр)
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Высшее (Магистр)
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Кандидат Наук
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Доктор наук
                        </span>
                                </li>
                            </ul>
                            <div class="invalid-feedback">
                                Please select a valid state.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 register-col">
                        <label for="validationTooltip-lang3" class="form-label register-label font-psb">Уровень владения</label>
                        <div class="select-menu login-input">
                            <div class="select-btn" id="validationTooltip-lang3">
                                <span class="sBtn-text">Выберите</span>
                                <i class="fi fi-ts-angle-down"></i>
                            </div>
                            <ul class="options">
                                <li class="option">
                        <span class="option-text">
                            Среднее
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Среднее специальное
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Не оконченное высшее (Бакалавр)
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Высшее (Магистр)
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Кандидат Наук
                        </span>
                                </li>
                                <li class="option">
                        <span class="option-text">
                            Доктор наук
                        </span>
                                </li>
                            </ul>
                            <div class="invalid-feedback">
                                Please select a valid state.
                            </div>
                        </div>
                    </div>
                    <!-- Other language fields similar to the example above -->
                    <div class="profile-form-btn-wrapper">
                        <button type="button" class=" profile-form-btn-delete"><span class="profile-form-span-delete"></span>Удалить язык</button>
                    </div>
               </div>`;

            const div = document.createElement('div');
            div.innerHTML = newLanguageHtml;
            languageContainer.appendChild(div);

            div.querySelector('.profile-form-btn-delete').addEventListener('click', function() {
                div.remove();
            });

            // Reinitialize dropdowns and other interactive elements
            setupDropdowns();

            console.log('New language section added.');
        });
    }
}

export default profileAddMoreLang;


