function toggles() {
    // Get the elements from the DOM
    const iconViewLong = document.querySelector('.icon-view-long');
    const iconViewShort = document.querySelector('.icon-view-short');
    const vacancyLong = document.querySelector('.vacancy-long');
    const vacancyShort = document.querySelector('.vacancy-short');

    // Verify all elements are present
    if (!iconViewLong || !iconViewShort || !vacancyLong || !vacancyShort) {
        return; // Stop execution if elements are missing
    }

    // Define the functions to show long/short vacancies
    const showLongView = () => {
        vacancyLong.style.display = 'block';
        vacancyShort.style.display = 'none';
        iconViewLong.classList.add('icon-active');
        iconViewShort.classList.remove('icon-active');
    };

    const showShortView = () => {
        vacancyLong.style.display = 'none';
        vacancyShort.style.display = 'flex';
        iconViewShort.classList.add('icon-active');
        iconViewLong.classList.remove('icon-active');
    };

    // Attach event listeners to icons
    iconViewLong.addEventListener('click', showLongView);
    iconViewShort.addEventListener('click', showShortView);

    // Initially display long vacancies
    showLongView();
}

// Call the toggles function
toggles();
