function uploadIcon() {
    document.addEventListener('DOMContentLoaded', function () {
        const fileInput = document.querySelector('.file-upload-input');
        const uploadIcon = document.querySelector('.upload-icon');
        const imagePreviewSpan = document.querySelector('.image-preview');
        const deleteImageButton = document.querySelector('.delete-image-btn');

        // Function to handle file input change event
        const handleFileInputChange = (event) => {
            const file = event.target.files[0];
            if (file && file.type === 'image/png' && file.size <= 5242880) { // 5MB
                const reader = new FileReader();

                reader.onload = (e) => {
                    // Update UI to reflect the uploaded image
                    uploadIcon.style.display = 'none';
                    imagePreviewSpan.innerHTML = ''; // Clear any previous image
                    const newImage = document.createElement('img');
                    newImage.src = e.target.result;
                    imagePreviewSpan.appendChild(newImage);
                    imagePreviewSpan.style.display = 'block';
                    deleteImageButton.style.display = 'inline';
                };

                reader.readAsDataURL(file);
            } else {
                alert('Please upload a PNG image smaller than 5MB.');
            }
        };

        // Function to handle delete image button click event
        const handleDeleteImageClick = () => {
            fileInput.value = ''; // Clear file input
            uploadIcon.style.display = 'block'; // Show upload icon again
            imagePreviewSpan.innerHTML = ''; // Clear image preview
            imagePreviewSpan.style.display = 'none';
            deleteImageButton.style.display = 'none'; // Hide delete button
        };

        // Attach event listeners if elements exist
        if (fileInput && uploadIcon && imagePreviewSpan && deleteImageButton) {
            fileInput.addEventListener('change', handleFileInputChange);
            deleteImageButton.addEventListener('click', handleDeleteImageClick);
        } else {

        }
    });
}

export default uploadIcon;
