function profileAddCertificate() {
    const addButton = document.getElementById('add-work-certificate');
    const certificateContainer = document.querySelector('.edit-certifications .certifications-container');

    if (!addButton || !certificateContainer) {
        console.error('');
        return;
    }

    if (!addButton.dataset.initialized) {
        addButton.dataset.initialized = true;

        addButton.addEventListener('click', event => {
            event.preventDefault();

            const currentCount = certificateContainer.querySelectorAll('.certification-section').length + 1;

            const newJobHtml = `
                <div class="row gy-2 gx-5 certification-section">
                    <div class="col-md-6 register-col">
                        <label class="form-label register-label font-psb" for="validationTooltip-cert-name-${currentCount}">Название Сертификата/Курса</label>
                        <div class="login-input">
                            <input class="form-control" id="validationTooltip-cert-name-${currentCount}" placeholder="Введите..." required type="text">
                            <div class="invalid-feedback">Please enter the name of the certificate/course.</div>
                        </div>
                    </div>
                    <div class="col-md-6 register-col">
                        <label class="form-label register-label font-psb" for="validationTooltip-org-name-${currentCount}">Название организации</label>
                        <div class="login-input">
                            <input class="form-control" id="validationTooltip-org-name-${currentCount}" placeholder="Введите..." required type="text">
                            <div class="invalid-feedback">Please enter the name of the organization.</div>
                        </div>
                    </div>
                    <div class="col-md-6 register-col">
                        <label class="form-label register-label font-psb" for="validationTooltip-receive-date-${currentCount}">Дата получения</label>
                        <div class="login-input">
                            <input class="form-control" id="validationTooltip-receive-date-${currentCount}" placeholder="Введите..." required type="text">
                            <div class="invalid-feedback">Please enter the date of receiving.</div>
                        </div>
                    </div>
                    <div class="col-md-6 register-col">
                        <label class="form-label register-label font-psb" for="validationTooltip-link-${currentCount}">Ссылка (если имеется)</label>
                        <div class="login-input">
                            <input class="form-control" id="validationTooltip-link-${currentCount}" placeholder="Введите..." type="text">
                            <div class="invalid-feedback">Please enter a valid link.</div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <label class="form-label register-label font-psb" for="upload-container-${currentCount}">Фото Сертификата (если имеется)</label>
                        <div class="certificate">
                            <div class="certificate-wrapper">
                                <label class="certificate__container" id="upload-container-${currentCount}">
                                    <span class="label-text">
                                    Выбери или перетащите файлы сюда. Максимальный размер файла 5 МБ. Допустимый формат JPEG, JPG, PNG.
                                    </span>
                                    <input class="certificate__file" id="upload-files-${currentCount}" type="file" accept="image/*" multiple="multiple"/>
                                </label>
                                <div class="certificate__files-container" id="files-list-container-${currentCount}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-form-btn-wrapper">
                        <button type="button" class="profile-form-btn-delete"><span class="profile-form-span-delete"></span>Удалить место работы</button>
                    </div>
                </div>
            `;

            const div = document.createElement('div');
            div.innerHTML = newJobHtml;
            const certificateSection = div.firstElementChild; // Get the first child (the section itself)
            certificateContainer.appendChild(certificateSection); // Append the new section to the container

            // Add event listener to the delete button in this newly added section
            certificateSection.querySelector('.profile-form-btn-delete').addEventListener('click', function() {
                certificateSection.remove(); // Remove this certificate section
            });

            // Initialize file upload functionality for the new section
            const newInputFile = certificateSection.querySelector(`#upload-files-${currentCount}`);
            const newInputContainer = certificateSection.querySelector(`#upload-container-${currentCount}`);
            const newFilesListContainer = certificateSection.querySelector(`#files-list-container-${currentCount}`);
            initializeFileUpload(newInputFile, newInputContainer, newFilesListContainer);
        });
    }
}

document.addEventListener('DOMContentLoaded', () => {
    profileAddCertificate();

    // Initialize file upload functionality for existing elements
    const initialInputFile = document.querySelector('#upload-files');
    const initialInputContainer = document.querySelector('#upload-container');
    const initialFilesListContainer = document.querySelector('#files-list-container');
    initializeFileUpload(initialInputFile, initialInputContainer, initialFilesListContainer);
});

export default profileAddCertificate;
