function registryForm() {
    const userType = document.querySelector('.register-page')?.classList.contains('student') ? 'student' : 'company';
    const sections = document.querySelectorAll('.register-student-section');
    const progressBar = document.getElementById('progress-bar');
    const totalSteps = sections.length;

    let currentStep = 0; // Start from the first page by default

    setupFormNavigation();
    updateFormSteps();

    function setupFormNavigation() {
        sections.forEach((section, index) => {
            const prevBtn = section.querySelector('.prevBtn');
            const nextBtn = section.querySelector('.nextBtn');

            prevBtn?.addEventListener('click', () => handlePrevNextButtons(-1, index));
            nextBtn?.addEventListener('click', () => handlePrevNextButtons(1, index));
        });

        document.querySelectorAll('form').forEach(formElement => {
            formElement.addEventListener('submit', handleFormSubmit);
        });
    }

    function handlePrevNextButtons(stepChange, index) {
        const currentSection = sections[currentStep];

        if (stepChange === 1 && !validateForm(currentSection)) {
            return; // Stop if the current section is not valid
        }

        let newStep = currentStep + stepChange;
        if ((stepChange === -1 && newStep >= 0) || (stepChange === 1 && newStep < totalSteps)) {
            currentStep = newStep;
            updateFormSteps();
        }
    }

    function validateForm(section) {
        const form = section.querySelector('.needs-validation');
        if (form.checkValidity()) {
            form.classList.remove('was-validated');
            return true;
        } else {
            form.classList.add('was-validated');
            return false;
        }
    }

    function updateFormSteps() {
        sections.forEach((section, index) => {
            section.style.display = index === currentStep ? 'block' : 'none';
        });
        const progressPercentage = (currentStep / (totalSteps - 1)) * 100;
        progressBar.style.width = `${progressPercentage}%`;
    }

    function handleFormSubmit(event) {
        event.preventDefault();
        // Add logic for when form is submitted
        alert('Form submitted successfully!');
    }

    // Initialize form steps
    updateFormSteps();
}

export default registryForm;
