function setupModalScroll() {
    const modals = document.querySelectorAll('.modal');

    modals.forEach(modal => {
        // Listen for the modal opening
        modal.addEventListener('show.bs.modal', () => {
            const scrollY = window.scrollY; // Capture the scroll position
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            document.body.style.overflow = 'hidden';
            document.body.style.top = `-${scrollY}px`; // Offset for the scroll position
        });

        // Listen for the modal closing
        modal.addEventListener('hidden.bs.modal', () => {
            const scrollY = document.body.style.top; // Retrieve the scroll position
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.overflow = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1); // Reset scroll position to original
        });
    });
}

// Assuming ES6 modules are used, export this function
export default setupModalScroll;
