import $ from "jquery";
import "slick-carousel";

//Slider

export default function initSlickSliders(){
    $(document).ready(function (){
        $('.partners_slider').slick({
            autoplay: true,
            arrows:true,
            autoplaySpeed: 2000,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 5,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1513,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
    $(document).ready(function (){
        $('.company_slider').slick({
            autoplay: true,
            arrows:true,
            autoplaySpeed: 5000,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
    $(document).ready(function (){
        $('.history_slider').slick({
            centerMode: true,
            centerPadding: '0',
            autoplay:true,
            autoplaySpeed: 2000,
            infinite: true,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        arrows: true,
                        centerMode: false,
                        centerPadding: '0',
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: true,
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        centerMode: false,
                        centerPadding: '0',
                        slidesToShow: 1
                    }
                }
            ]
        });
    });
    $(document).ready(function (){
        $('.profile_slider').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
            arrows: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            prevArrow: '<button type="button" class="slick-arrow slick-prev">Назад</button>',
            nextArrow: '<button type="button" class="slick-arrow slick-next">Следующий</button>',

        });
    });
    $(document).ready(function (){
        $('.event-previous-slider').slick({
            autoplay: true,
            arrows:true,
            autoplaySpeed: 2000,
            dots: false,
            infinite: true,
            speed: 300,
            adaptiveHeight:true,
            slidesToShow: 5,
            slidesToScroll: 2,
            variableWidth:true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
    $(document).ready(function (){
        $('.insights_slider').slick({
            autoplay: true,
            arrows:true,
            autoplaySpeed: 2000,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1514,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1026,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 650,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,

                        adaptiveHeight: true
                    }
                }

            ]
        });
    });
}
