// File: burger-scroll.js

export default function setupBurgerScroll() {
    const offcanvasElements = document.querySelectorAll('.offcanvas');
    const body = document.body;

    offcanvasElements.forEach(offcanvas => {
        offcanvas.addEventListener('show.bs.offcanvas', function () {
            const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
            document.documentElement.style.overflow = 'hidden';
            body.style.overflow = 'hidden';
            body.style.paddingRight = `${scrollbarWidth}px`;
        });

        offcanvas.addEventListener('hidden.bs.offcanvas', function () {
            document.documentElement.style.overflow = '';
            body.style.overflow = '';
            body.style.paddingRight = '';
        });
    });
}
