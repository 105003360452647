import initSlickSliders from './modules/slick-slider.js';
import form from "./modules/form";
import uploadIcon from "./modules/upload-icon";
import toggles from "./modules/toggles";
import registryForm from "./modules/registry-form";
import setupModalScroll from "./modules/modal";
import profileAddMore from "./modules/profile-add-more";
import profileAddMoreLang from "./modules/profile-add-more-lang";
import setupDropdowns from "./modules/dropdown";
import profileAddJob from "./modules/profile-add-job";
import profileAddCertificate from "./modules/profile-add-certificate";
import setupBurgerScroll from "./modules/burger-scroll";


setupBurgerScroll()

document.addEventListener('DOMContentLoaded', () => {

    // Setup functions
    function setupModalActions() {
        setupModalScroll();

    }

    function setupHeaderScroll() {
        const header = document.querySelector('.header');
        if (!header) {
            console.error('Header element not found');
            return;
        }

        function checkHeaderScroll() {
            header.classList.toggle('header-scrolled', window.scrollY > 0);
        }

        document.addEventListener('scroll', checkHeaderScroll);
        checkHeaderScroll(); // Initial check
    }

    function setupPasswordToggle() {
        document.querySelectorAll('.eyeicon').forEach(eyeIcon => {
            eyeIcon.addEventListener('click', () => {
                const input = eyeIcon.closest('.login-input').querySelector('input');
                const isPassword = input.type === 'password';
                input.type = isPassword ? 'text' : 'password';
                eyeIcon.src = isPassword ? '../img/pngImage/eye-closed.png' : '../img/pngImage/eye-closed.png';
            });
        });
    }

    function setupFormSubmission() {
        const loginForm = document.getElementById('loginForm');
        loginForm?.addEventListener('submit', event => {
            event.preventDefault();
            // Perform any validation or data processing here
            window.location.href = 'profile-respond.html';
        });
    }

    function setupInputFocus() {
        const loginInputs = document.querySelectorAll('.login-input');
        loginInputs.forEach(input => {
            input.addEventListener('click', event => {
                event.stopPropagation();
                loginInputs.forEach(inp => inp.classList.remove('active-border'));
                input.classList.add('active-border');
            });
        });

        document.addEventListener('click', () => {
            loginInputs.forEach(inp => inp.classList.remove('active-border'));
        });

        const containers = document.querySelectorAll('.edit-education, .edit-languages');

        containers.forEach(container => {
            container.addEventListener('click', event => {
                const target = event.target.closest('.login-input');
                if (target) {
                    // Clicked within a login-input
                    event.stopPropagation(); // Stop the event from bubbling up further
                    document.querySelectorAll('.login-input').forEach(inp => inp.classList.remove('active-border'));
                    target.classList.add('active-border');
                }
            });
        });

        // Clicking anywhere else in the document should remove the active border
        document.addEventListener('click', () => {
            document.querySelectorAll('.login-input').forEach(inp => inp.classList.remove('active-border'));
        });
    }
    function setupShareLink() {
        const shareLinks = document.querySelectorAll('.share-link');
        const shareDropdowns = document.querySelectorAll('.share-dropdown');

        shareLinks.forEach((shareLink, index) => {
            const shareDropdown = shareDropdowns[index];
            console.log('Attaching event to', shareLink, 'with dropdown', shareDropdown); // Debug log

            if (shareLink && shareDropdown) {
                // Function to toggle dropdown visibility
                shareLink.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    console.log('Toggling dropdown'); // Debug log

                    if (shareDropdown.style.display === 'none' || shareDropdown.style.display === '') {
                        shareDropdown.style.display = 'block';
                    } else {
                        shareDropdown.style.display = 'none';
                    }
                });

                // Event listener to close the dropdown when clicking outside of it
                document.addEventListener('click', (e) => {
                    if (!shareLink.contains(e.target) && !shareDropdown.contains(e.target)) {
                        if (shareDropdown.style.display === 'block') {
                            shareDropdown.style.display = 'none';
                        }
                    }
                });

                // Prevent event propagation when clicking inside the dropdown
                shareDropdown.addEventListener('click', (e) => {
                    e.stopPropagation();
                });
            }
        });
    }


    // Initialize all setup functions
    setupModalActions();
    setupHeaderScroll();
    setupPasswordToggle();
    setupFormSubmission();
    setupInputFocus();
    setupShareLink()

    // Other initializations
    profileAddJob()
    profileAddMore();
    profileAddMoreLang()
    profileAddCertificate()
    setupDropdowns()
    form();
    initSlickSliders();
    registryForm();
    toggles();

});

uploadIcon();

